import { Button, Checkbox, Flex, Group, Input, Modal, SimpleGrid, Skeleton } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FC, Fragment, memo, useEffect, useMemo } from 'react';
import { getSuppliers } from 'src/services/automation.service';
import { ModalPropsType } from './types/modal-props';
import { useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';

export const CreateStoreModal: FC<ModalPropsType<any>> = memo(({ modalProps, onSubmit, isSubmitting }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { 
    data,
    isFetching: isSuppliersFetching,
    isLoading: isSuppliersLoading,
    refetch: refetchSuppliers 
  } = useQuery({
    queryKey: ['suppliers'],
    queryFn: async () => getSuppliers(await getAccessTokenSilently())
  });

  const suppliers = useMemo(() => data || [], [data]);

  useEffect(() => {
    if (modalProps.opened) {
      refetchSuppliers();
    }
  }, [modalProps.opened, refetchSuppliers]);

  const formikInitialValues = useMemo(() => {
    return suppliers.map(({ name }) => ({
      name,
      selected: false,
      apiKey: '',
    }));
  }, [suppliers]);

  const formik = useFormik({
    initialValues: { suppliers: formikInitialValues },
    enableReinitialize: true,
    onSubmit: async ({ suppliers }) => {
      const submitValue = {
        supplier_names: [] as Array<string>,
        supplier_api_keys: [] as Array<string>,
      };

      suppliers.forEach(({ selected, apiKey, name }) => {
        if (selected) {
          submitValue.supplier_names.push(name);
          submitValue.supplier_api_keys.push(apiKey)
        }
      });

      await onSubmit(submitValue);
      modalProps.onClose();
    },
  });

  const { values, handleChange, handleSubmit } = formik;

  return (
    <Modal {...modalProps}>
      <Skeleton visible={isSuppliersFetching || isSuppliersLoading}>
        <SimpleGrid
          cols={2}
          spacing="xs"
          verticalSpacing="lg"
          style={{
            gridTemplateColumns: "auto 1fr"
          }}
        >
          {values.suppliers.map(({ name, selected, apiKey }, index) => (
            <Fragment key={name}>
              <Flex
                align="center"
              >
                <Checkbox
                  checked={selected}
                  name={`suppliers[${index}].selected`}
                  onChange={handleChange}
                  label={name}
                />
              </Flex>
              <Input
                placeholder="API key"
                value={apiKey}
                name={`suppliers[${index}].apiKey`}
                onChange={handleChange}
              />
            </Fragment>
          ))}
        </SimpleGrid>
      </Skeleton>
      <Group mt={20} justify='end'>
        <Button
          disabled={isSuppliersFetching || isSuppliersLoading || isSubmitting}
          onClick={handleSubmit as any}
        >
          Create store
        </Button>
      </Group>
    </Modal>
  )
});
