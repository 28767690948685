import React from "react";
import { SimpleGrid, Paper, Text, Box, Image } from '@mantine/core';

// generic component that renders a grid of buttons for the selection of choices

interface Props {
    choices: any[]; // array of objects that contains the choice, the icon and the callback 
}

const ButtonsGrid: React.FC<Props> = ({ choices }) => {
    return (
        <SimpleGrid cols={2} spacing={"xl"}>
            {choices.map((choice, idx) => (
                <Paper key={idx} shadow="xs" onClick={choice.callback} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: '743px', width: "603px", backgroundColor: '#d9d9d9' }}>
                    <Box className="button-grid-icon" style={{ display: "flex", justifyContent: "center", height: '80%', width: "100%", alignItems: "center", backgroundColor: '#d9d9d9' }}>
                        <Image src={choice.icon} alt={choice.choice} width={143} height={143}/>
                    </Box>
                    <Box className="button-grid-icon" style={{ display: "flex", justifyContent: "center", height: '20%', width: "100%", alignItems: "center", backgroundColor: 'white' }}>
                        <Text style={{color: 'black', fontSize: '26px', fontWeight: 700}}>{choice.choice}</Text>
                    </Box>
                </Paper>
            ))}
        </SimpleGrid>
    );
};

export default ButtonsGrid;