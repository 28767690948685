import React from "react";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
      <div >{children}</div>
  );
};
