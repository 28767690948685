import React from "react";

export const PageLoader: React.FC = () => {
  const loadingImg = "https://lh3.googleusercontent.com/a/ACg8ocKYyPib_Fzp1UEpMLn3QZgNJsFce1srbIRbovqDvffPgg=s96-c";

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};
