import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLayout } from "../components/page-layout";
import ButtonsGrid from "../components/grids/buttons-grid";
import plusicon from "../assets/icons/plus-icon.svg";
import producticon from "../assets/icons/product-icon.svg";
import { Box, Text } from "@mantine/core";
import { useNavigate } from 'react-router-dom';



export const UserHome: React.FC = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  if (!user) {
    return null;
  }

  return (
    <PageLayout>
      <div style={{ display: "flex",  justifyContent: "center", alignItems: "center", flexDirection: "column", height: '100%' }}>
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '212px' }}>
            <Text style={{color: '#ffffff', fontFamily: "Krona One-Regular", fontSize: '48px', fontWeight: 400}}>ANDROMEDA</Text>
          </Box>
          <ButtonsGrid
            choices={[
              {
                choice: "Create or select a new Influencer",
                icon: plusicon,
                callback: () => navigate('/persona-creator'),
              },
              {
                choice: "Personas Dashboard",
                icon: producticon,
                callback: () => navigate('/personas-dashboard'),
              },
            ]}
          />
        </div>
    </PageLayout>
  );
};
