// React component code with Stepper functionality
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Box,  Stepper } from '@mantine/core';
import { PageLayout } from '../components/page-layout';
import { TargetAudienceView } from 'src/components/views/target-audience-view';
import { CreateProductTemplatesView } from 'src/components/views/sync-products-view';
import Persona from "../models/persona";  // Assuming you have a Persona model
import { GenerateArtworksViewV2 } from 'src/components/views/generate-artwork-view-v2';
import { useAuth0 } from '@auth0/auth0-react';
import { useWebsocketClient } from 'src/modules/websocket-client';
import { deleteArtwork as _deleteArtwork, startArtworksGeneration } from 'src/services/automation.service';


export const ProductsCreatorPage: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [targetAudiences, setTargetAudiences] = useState<any[]>([]);
  const [artworkPerTargetAudience, setArtworkPerTargetAudience] = useState<number[]>([]);

  const [selectedPsdTemplate, setSelectedPsdTemplate] = useState<string>("");
  const [selectedModel, setSelectedModel] = useState<string>("Dalle 3");
  const [selectedPersona, setSelectedPersona] = useState<Persona[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const [processingArtworks, setProcessingArtworks] = useState<Record<string, { message: string; sqs_task_id: string, artwork_task_id: string, image_url?: string, final_image?: string, targetAudience?: any, prompt?: { prompt?: string }; }>>({});
  const { isConnected, websocketClient } = useWebsocketClient();

  const startGenerateArtworks = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const targetAudiencesWithLimits = targetAudiences.map((targetAudience, index) => ({
      ...targetAudience,
      limit: artworkPerTargetAudience[index] ? artworkPerTargetAudience[index] : 0
    }));

    const targetAudiencesWithout0Limits = targetAudiencesWithLimits.filter(targetAudience => targetAudience.limit !== 0);

    const promises: Array<Promise<{
      message: string,
      artwork_task_id: string,
      sqs_task_id: string,
    }>> = [];

    targetAudiencesWithout0Limits.forEach((audience) => {
      for (let i = 0; i < audience.limit; i++) {
        promises.push(startArtworksGeneration(accessToken, audience, selectedPsdTemplate, selectedModel))
      }
    });

    const artworks = await Promise.allSettled<{
      message: string,
      artwork_task_id: string,
      sqs_task_id: string,
    }>(promises);

    const newProcessingMap: Record<string, { message: string, sqs_task_id: string, artwork_task_id: string }> = {};

    artworks.forEach((promiseResult) => {
      switch (promiseResult.status) {
        case 'fulfilled':
          const { value } = promiseResult;
          const { artwork_task_id } = value;
          newProcessingMap[artwork_task_id] = value;
        break;
      }
    });

    setProcessingArtworks((_) => newProcessingMap);
  }, [getAccessTokenSilently, artworkPerTargetAudience, selectedModel, selectedPsdTemplate, targetAudiences]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    const handleMessage = (event: MessageEvent<string>) => {
      try {
        const { data } = event;

        const parsedData = JSON.parse(data);
        console.log(parsedData, 'new event message')

        if (!parsedData['artwork_task_id']) {
          return;
        }

        setProcessingArtworks((prev) => {
          const target = prev[parsedData['artwork_task_id']];

          if (!target){
            return prev;
          }

          prev[parsedData['artwork_task_id']] = { ...target, ...parsedData };
          // send ack to server

          return { ...prev };
        });
      } catch (error) {
        // console.error(error);
      }
    };

    websocketClient.webSocketInstance.addEventListener('message', handleMessage)
          // send a message to the server {"action": "RecieveMessage"}
    websocketClient.webSocketInstance.send(JSON.stringify({ action: "RecieveMessage" }));
    return () => {
      websocketClient.webSocketInstance.removeEventListener('message', handleMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const processingArtworksEntries = useMemo(() => {
    return Object.entries(processingArtworks);
  }, [processingArtworks]);

  const deleteArtwork = useCallback(async (sqsTaskId: string, artworkTaskId: string) => {
    const accessToken = await getAccessTokenSilently();

    _deleteArtwork(accessToken, sqsTaskId);

    setProcessingArtworks((prev) => {
      delete prev[artworkTaskId]

      return { ...prev };
    });
  }, [getAccessTokenSilently]);

  const nextStep = () => setActiveStep((prev: number) => {
    if (prev >= 2) {
      return prev;
    }

    if (prev === 0) {
      startGenerateArtworks();
    }

    return prev + 1;
  });

  const prevStep = () => setActiveStep((prev: number) => {
    if (prev <= 0) {
      return prev;
    }

    if (prev === 1) {
      processingArtworksEntries.forEach(([_, { sqs_task_id, artwork_task_id }]) => {
        deleteArtwork(sqs_task_id, artwork_task_id);
      })

      setProcessingArtworks({});
    }

    return prev - 1;
  });

  const selectedArtworks = useMemo(() => {
    return processingArtworksEntries.reduce<Array<{ artwork: string, targetAudience: any, prompt?: string }>>(((accum: Array<any>, current) => {
      const [, { final_image, prompt, targetAudience }] = current;

      if (final_image) {
        return [...accum, { artwork: final_image, prompt: prompt?.prompt, target_audience: targetAudience }];
      }

      return accum;
    }), [] as Array<{ prompt?: string, artwork: string, targetAudience: any }>)
  }, [processingArtworksEntries]);

  const isNextStepDisabled = useMemo(() => {
    const someNotReady = processingArtworksEntries.some(([_, { final_image }]) => {
      return !final_image
    });

    if (someNotReady) {
      return true;
    }

    return selectedPsdTemplate === "" && selectedPersona ? true : false || (activeStep === 1 && selectedArtworks.length === 0);
  }, [activeStep, processingArtworksEntries, selectedArtworks.length, selectedPersona, selectedPsdTemplate]);

  return (  
    <PageLayout>
      <Box style={{ padding: 20 }}>
        <Stepper active={activeStep} onStepClick={setActiveStep}>
          <Stepper.Step
            label="Select Target Audiences"
            description="From the trends of your persona, identify potential target audiences"
          >
            <TargetAudienceView
              targetAudiences={targetAudiences}
              setTargetAudiences={setTargetAudiences}
              artworkPerTargetAudience={artworkPerTargetAudience}
              setArtworkPerTargetAudience={setArtworkPerTargetAudience}
              selectedPsdTemplate={selectedPsdTemplate}
              setSelectedPsdTemplate={setSelectedPsdTemplate}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              selectedPersona={selectedPersona}
              setSelectedPersona={setSelectedPersona}
            />
          </Stepper.Step>

          <Stepper.Step
            label="Generate Artworks"
            description="Generate t-shirt designs to pick from"
          >
            <GenerateArtworksViewV2
              processingArtworksEntries={processingArtworksEntries}
              deleteArtwork={deleteArtwork}
            />
          </Stepper.Step>

          <Stepper.Step
            label="Create & Sync Product Templates"
            description="From the artworks you selected, Printful product templates will be created and synced to the DB."
          >
            <CreateProductTemplatesView
              selectedArtworks={selectedArtworks}
            />
          </Stepper.Step>
        </Stepper>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <Button onClick={prevStep} variant="default">
            Previous
          </Button>
          {activeStep !== 2 && 
            <Button
              onClick={nextStep}
              disabled={isNextStepDisabled}
            >
              Next Step
            </Button>
          }
        </Box>
      </Box>
    </PageLayout>
  );
};

