import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { ProductProvider } from "./selected-products-provider";
import { PrintfulProvider } from "./printful-context-provider";
import { NicheProvider } from "./selected-niche-provider";
import { StepperProvider } from "./stepper-provider";
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Burger, Group } from '@mantine/core';
import { NavBarButtons } from "./components/navigation/desktop/nav-bar-buttons";
import { NavBarTabs } from "./components/navigation/desktop/nav-bar-tabs";
import { PersonaProvider } from "./selected-personas-provider";
import { appRoutes } from "./router";
import { ModalManagerProvider } from "./components/modals";


export const App: React.FC = () => {
  const { isLoading } = useAuth0();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  return (
    <ModalManagerProvider>
      <PersonaProvider>
        <PrintfulProvider>
          <StepperProvider>
            <NicheProvider>
              <ProductProvider>
                <AppShell
                  header={{ height: 60 }}
                  navbar={{
                    width: 300,
                    breakpoint: 'sm',
                    collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
                  }}
                  padding="md"
                >
                  <AppShell.Header>
                    <Group h="100%" px="md">
                      <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
                      <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
                      <img
                      className="nav-bar__logo"
                      src="https://lh3.googleusercontent.com/a/ACg8ocKYyPib_Fzp1UEpMLn3QZgNJsFce1srbIRbovqDvffPgg=s96-c"
                      alt="Andromedashboard Logo"
                      style={{ width: 30, height: 30 }}

                    />
                    </Group>
                  </AppShell.Header>
                  <AppShell.Navbar p="md">
                    <AppShell.Section>
                      <NavBarButtons />
                    </AppShell.Section>
                    <AppShell.Section>
                      <NavBarTabs />
                    </AppShell.Section>
                  </AppShell.Navbar>
                  <AppShell.Main >
                    {appRoutes}
                  </AppShell.Main>
                </AppShell>
              </ProductProvider>
            </NicheProvider>
          </StepperProvider>
        </PrintfulProvider>
      </PersonaProvider>
    </ModalManagerProvider>
  );
};
