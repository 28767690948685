import { FC, memo } from 'react';
import { PageLayout } from 'src/components/page-layout';
import { SettingsView } from 'src/components/views/settings-view';
import { SettingsPageProvider } from 'src/settings-page-provider';

export const UserSettings: FC<{}> = memo(() => {
  return (
    <SettingsPageProvider>
      <PageLayout>
        <SettingsView />
      </PageLayout>
    </SettingsPageProvider>
  );
});