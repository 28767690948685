import React from "react";

export const HeroBanner: React.FC = () => {

  return (
    <div className="hero-banner hero-banner--pink-yellow">
      <div className="hero-banner__logo">
      </div>
      <h1 className="hero-banner__headline">La chanckla</h1>
      <p className="hero-banner__description">
        Nik sa maire
      </p>
      
    </div>
  );
};
