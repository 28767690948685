import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { MantineProvider, createTheme } from '@mantine/core';
import { Paper, Image, SimpleGrid } from '@mantine/core';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WebSocketContext } from "./modules/websocket-client";


const theme = createTheme({
  /** Background of grid white */
  components: {
    Paper: Paper.extend({
      classNames: {
        root: 'my-root-class',
      },
    //   styles: {
    //     root: { backgroundColor: 'grey',
    //             height: '700px',
    //   },
    // },
    }),
    Image: Image.extend({
      classNames: {
        root: 'my-root-class',
      },
    }),
    SimpleGrid: SimpleGrid.extend({
      classNames: {
        root: 'my-root-class',
      },
      styles: {
        root: { height: '100%',
       },
      },
    }),
  },
});

const queryClient = new QueryClient();

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
    <BrowserRouter>
      <WebSocketContext>
        <MantineProvider defaultColorScheme="dark" theme={theme}>
          <Auth0ProviderWithNavigate>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </Auth0ProviderWithNavigate>
        </MantineProvider>
      </WebSocketContext>
    </BrowserRouter>
);
