import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { getPersonasData, deletePersonas } from "../services/automation.service";  // Assuming you have a getPersonaData function
import PersonaGrid from "../components/grids/personas-grid";  // Assuming you have a PersonaGrid component
import Persona from "../models/persona";  // Assuming you have a Persona model
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Text, Button } from '@mantine/core';

export const PersonaDashboard: React.FC = () => {
  const [personas, setPersonas] = useState<Persona[]>([]);  // State to hold Persona data
  const [selectedPersonas, setSelectedPersonas] = useState<Persona[]>([]);


  const { getAccessTokenSilently } = useAuth0();

  const getPersonaData = async () => {
    const accessToken = await getAccessTokenSilently();
    const { data, error } = await getPersonasData(accessToken);

    if (data) {
        const parsed = JSON.parse(JSON.stringify(data, null, 2));
        const filteredPersonas = parsed.map((persona: any) => {
        const filtered = Object.fromEntries(
            Object.entries(persona).filter(
            ([key]) => !key.startsWith('__') && !key.startsWith('_')
            )
        );
        return filtered;
        });

        setPersonas(filteredPersonas);
    }

    if (error) {
        console.error(error);      }
    };

  useEffect(() => {
    let isMounted = true;
    
    if (!isMounted) {
        return;
    }
    

    getPersonaData();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  return (
    <PageLayout>
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        <Text style={{ color: '#ffffff', fontSize: '48px', fontWeight: 400 }}>
          Persona Dashboard
        </Text>
        <Box style={{ width: "91%" }} >
          <PersonaGrid personas={personas} selectedPersonas={selectedPersonas} setSelectedPersonas={setSelectedPersonas}/>
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "91%", padding: 20 }}>
          <Button
            onClick={async () => {
              const accessToken = await getAccessTokenSilently();
              await deletePersonas(accessToken, selectedPersonas).then(() => {
                getPersonaData();
                setSelectedPersonas([]);
              });
            }}
          >
            Delete Selected
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};
