import { Box, Button, Card, Group, SimpleGrid, Skeleton, Text } from '@mantine/core';
import { FC, memo } from 'react';
import { Image } from '@mantine/core';


interface GenerateArtworksViewV2Props {
  processingArtworksEntries: [string, {
    message: string;
    sqs_task_id: string;
    artwork_task_id: string;
    image_url?: string;
    final_image?: string;
    prompt?: { prompt?: string };
  }][],
  deleteArtwork: (sqsTaskId: string, artworkTaskId: string) => Promise<void>
}

export const GenerateArtworksViewV2: FC<GenerateArtworksViewV2Props> = memo(({
  processingArtworksEntries,
  deleteArtwork
}) => {
  return (
    <Box style={{ padding: 20 }}>
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 5 }}
      >
        {processingArtworksEntries.map(([id, { message, sqs_task_id, artwork_task_id, image_url, final_image, prompt }]) => {
          return (
            <Card shadow="sm" padding="lg" radius="md" withBorder key={id}>
              <Card.Section>
                {/* will be image inside skeleton on load */}
                <Skeleton visible={!(!!final_image || !!image_url)} height={100} >
                  <Image
                    src={final_image || image_url}
                    alt="artwork"
                    height="100%"
                    style={{
                      objectFit: "contain"
                    }}
                  />
                </Skeleton>
                
              </Card.Section>
              <Group justify="space-between" mt="md" mb="xs">
                {!final_image && <Text lineClamp={1} hidden={!final_image}>
                  {prompt?.prompt || message}
                </Text>}
              </Group>
              <Button
                color="red" fullWidth mt="md" radius="md"
                onClick={() => {
                  deleteArtwork(sqs_task_id, artwork_task_id);
                }}
              >
                Delete
              </Button>
            </Card>
          );
        })}
      </SimpleGrid>
    </Box>
  );
});
