import { useAuth0 } from "@auth0/auth0-react";
import React, { useMemo } from "react";
import { NavLink } from '@mantine/core';
import { useNavigate } from 'react-router-dom';



export const NavBarTabs: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const navigationLinksProperties = useMemo(() => [
    {
      label: 'Products Dashboard',
      onClick: () => navigate('/products-dashboard'),
      key: '/products-dashboard'
    },
    {
      label: 'Personas Dashboard',
      onClick: () => navigate('/personas-dashboard'),
      key: '/personas-dashboard'
    },
    {
      label: 'Personas Creator',
      onClick: () => navigate('/persona-creator'),
      key: '/persona-creator'
    },
    {
      label: 'Products Creator',
      onClick: () => navigate('/products-creator'),
      key: '/products-creator',
    },
    {
      label: 'Settings',
      onClick: () => navigate('/settings'),
      key: '/settings'
    }
  ], [navigate])

  return (
    <div className="nav-bar__tabs">
      <NavLink data-route="/user-home" label="Home" onClick={() => navigate('/user-home')} />
      {isAuthenticated && (
        <>
          {navigationLinksProperties.map((props) => (
            <NavLink {...props} />
          ))}
        </>
      )}
    </div>
  );
};
