import { Box, Button, Center, Divider, Flex, Input, SimpleGrid, Skeleton, Text } from '@mantine/core';
import { FC, Fragment, memo, useEffect } from 'react';
import { useSettingsPageContext } from 'src/settings-page-provider';
import { ModalName, useModalManagerProvider } from 'src/components/modals';

export const SettingsView: FC<{}> = memo(() => {
  const { authorizedUser, targetUserFromDb, isUsersFetching, isUserFetching, createUser, targetUserSuppliers } = useSettingsPageContext();
  const { getModalState, subscribeModalSubmit, unsubscribeModalSubmit, } = useModalManagerProvider();

  const [, { open: openStoreModal }] = getModalState(ModalName.CREATE_STORE_MODAL);


  useEffect(() => {
    const submitHandler = async (submitValue: any) => {
      if (!authorizedUser) {
        return;
      }

      createUser({
      ...submitValue,
      user_id: authorizedUser?.email
      });
    };

    subscribeModalSubmit(ModalName.CREATE_STORE_MODAL, submitHandler);

    return () => {
      unsubscribeModalSubmit(ModalName.CREATE_STORE_MODAL, submitHandler);
    }
  }, [authorizedUser, createUser, subscribeModalSubmit, unsubscribeModalSubmit]);

  return (
    <Box>
      <Skeleton visible={isUserFetching}>
        <Center>
          <Text
            fz={20}
            fw={'bold'}
          >
            {authorizedUser?.email}
          </Text>
        </Center>
      </Skeleton>

      {!targetUserFromDb && (
        <Skeleton visible={isUsersFetching}>
          <Center>
            <Text>
              No store registered, please register your first store
            </Text>
          </Center>
          <Center my={10}>
            <Button title="register new store" onClick={openStoreModal}>
              register new store
            </Button>
          </Center>
        </Skeleton>
      )}

      <Divider my={10} />

      <Text ta="center" my={10}>
        Suppliers list
      </Text>

      <Skeleton visible={isUserFetching}>
        {targetUserFromDb &&
          <SimpleGrid
            cols={2}
            spacing="xs"
            verticalSpacing="lg"
            style={{
              gridTemplateColumns: "auto 1fr"
            }}
          >
            {targetUserSuppliers && targetUserSuppliers.map(({ name, apiKey }, index) => (
              <Fragment key={name}>
                <Flex
                  align="center"
                >
                  <Text>
                    {name}
                  </Text>
                </Flex>
                <Input
                  placeholder="API key"
                  value={apiKey}
                  readOnly={true}
                />
              </Fragment>
            ))}
          </SimpleGrid>
        }
      </Skeleton>
    </Box>
  )
});
