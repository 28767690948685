import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";

interface StepperContextProps {
  selectedSteppers: number;
  setSelectedSteppers: Dispatch<SetStateAction<number>>;
}

const StepperContext = createContext<StepperContextProps | undefined>(undefined);

interface StepperProviderProps {
  children: ReactNode;
}

export const useSteppers = (): StepperContextProps => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useSteppers must be used within a StepperProvider");
  }
  return context;
};

export const StepperProvider: React.FC<StepperProviderProps> = ({ children }) => {
  const [selectedSteppers, setSelectedSteppers] = useState<number>(0);
  return (
    <StepperContext.Provider value={{ selectedSteppers, setSelectedSteppers }}>
      {children}
    </StepperContext.Provider>
  );
};
