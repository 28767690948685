import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import { getProductData, refreshDatabase, syncProductsOnAmazon, updateAttributes, deleteProducts } from "../services/automation.service";
import ProductGrid from "../components/grids/product-grid";  
import Product  from "../models/product";
import { AdvancedFiltersSearch } from "../components/advanced-search-filter";
import { useProducts } from "../selected-products-provider";
import { Text, Box, Button, LoadingOverlay, Progress } from '@mantine/core';

export const ProductsDashboard: React.FC = () => {
  const [token, setToken] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [syncedProducts, setSyncedProducts] = useState<Product[]>([]);
  const [refreshedProducts, setRefreshedProducts] = useState<Product[]>([]);

  const { selectedProducts } = useProducts();
  const { getAccessTokenSilently } = useAuth0();
  const getProductsData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
      const { data, error } = await getProductData(accessToken);
      if (data) {
        setProducts(data);

        setFilteredProducts(data); // Initialize with all products
      }
      if (error) {
        setError('Failed to fetch products.');
      }
    } catch (error) {
      console.error(error);
      setError('An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);

    

    getProductsData();
  }, [getAccessTokenSilently]);

  const handleSyncProductsOnAmazon = async () => {
    setSyncedProducts([]);
    setIsLoading(true);
    try {
      await syncProductsOnAmazon(token, selectedProducts, (product) => {
        console.log(product);
        setSyncedProducts(syncedProducts => [...syncedProducts, product]);
        setSyncedProducts([]);
      }
      );
      if (error) {
        setError('Failed to sync products.');
      }
    } catch (error) {
      console.error(error);
      setError('An unexpected error occurred.');
    }
    
    setIsLoading(false);

  }

  const handleUpdateAttributes = async () => {
    setIsLoading(true);
    try {
      await updateAttributes(token, selectedProducts);
      if (error) {
        setError('Failed to update attributes.');
      }
    } catch (error) {
      console.error(error);
      setError('An unexpected error occurred.');
    }
  }

  const handleRefreshDatabase = async () => {
    setRefreshedProducts([]);
    setIsLoading(true);
    try {
      await refreshDatabase(token, (product) => {
        console.log(product);
        setRefreshedProducts(refreshedProducts => [...refreshedProducts, product]);
        if (refreshedProducts.length === products.length) {
          setIsLoading(false);
          setRefreshedProducts([]);
        }
      }
      );
      if (error) {
        setError('Failed to refresh database.');
      }
    } catch (error) {
      console.error(error);
      setError('An unexpected error occurred.');
    }
    
  }


  return (
    <PageLayout>
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        {(isLoading && syncedProducts.length === 0 && refreshedProducts.length === 0) && <LoadingOverlay visible />}
        {error && <Text color="red">{error}</Text>}

        <Text style={{ color: '#ffffff', fontSize: '48px', fontWeight: 400 }}>
          Products Dashboard
        </Text>

        <AdvancedFiltersSearch
          products={products}
          onFilter={setFilteredProducts}
        />

        <Box style={{ width: "91%" }}>
          <ProductGrid products={filteredProducts} />
        </Box>

      
        { !isLoading && <>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "100%",
              height: "40%",
              padding: "2%",
            }}
          >
            <Button
              style={{
                width: "30%",
                height: "54px",
                backgroundColor: "#F6F6F6",
                color: "#000000",
                fontSize: "20px",
                borderRadius: "0px",
                boxShadow: "0px 4px 15px 4px rgba(0, 1, 0, 0.25)",
              }}
              onClick={() => {
                handleRefreshDatabase();
              }}
            >
              REFRESH DATABASE
            </Button>
            <Button
              style={{
                width: "30%",
                height: "54px",
                backgroundColor: "#F6F6F6",
                color: "#000000",
                fontSize: "20px",
              }}
              onClick={() => {
                handleUpdateAttributes().then(() => {

                  getProductsData()
                  setIsLoading(false);
                  
                });
              }}
            >
              GENERATE AMAZON ATTRIBUTES FOR SELECTED PRODUCTS
            </Button>
            <Button
              style={{
                width: "30%",
                height: "54px",
                backgroundColor: "#F6F6F6",
                color: "#000000",
                fontSize: "20px",
              }}
              onClick={() => {
                console.log(selectedProducts);
              }}
            >
              GENERATE AMAZON MOCKUPS FOR SELECTED PRODUCTS
            </Button>
            <Button
              style={{
                width: "30%",
                height: "54px",
                backgroundColor: "#F6F6F6",
                color: "#000000",
                fontSize: "20px",
              }}
              onClick={() => {
                setIsLoading(true);
                deleteProducts(token, selectedProducts).then(() => {

                  getProductsData()
                  setIsLoading(false);
                  
                });
              }}
            >
              DELETE SELECTED PRODUCTS
            </Button>
          </Box>
        </>
      }
      { 
        (syncedProducts.length > 0 && isLoading) && (
          <Box style={{ padding: 20 }}>
        <Progress size="xl" value={(syncedProducts.length / selectedProducts.length) * 100} />
        
        <Text style={{ color: '#ffffff', fontSize: '48px', fontWeight: 400 }}>
          Syncing products to Amazon...
        </Text>

      </Box>)}
      { (refreshedProducts.length > 0 && isLoading) && (
        <Box style={{  padding: 20 }}>
        <Progress size="xl" value={(refreshedProducts.length / products.length) * 100} />
        
        <Text style={{ color: '#ffffff', fontSize: '48px', fontWeight: 400 }}>
          Refreshing database...
        </Text>

      </Box>)}
      </Box>
    </PageLayout>
  );
};
