import { 
  createContext,
  useContext,
  FC,
  memo,
  PropsWithChildren,
  createElement,
  useRef,
  useCallback,
  useState,
} from "react";
import { CreateStoreModal } from "./create-store-modal";
import { useDisclosure } from "@mantine/hooks";
import { ModalProps } from "@mantine/core";
import { ModalPropsType } from "./types/modal-props";

interface ModalManagerContextValue {
  getModalState: (modalName: ModalName) => readonly [boolean, {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  }];
  subscribeModalSubmit: (modalName: ModalName, subscriber: (submitValue: any) => Promise<void>) => void;
  unsubscribeModalSubmit: (modalName: ModalName, subscriber: (submitValue: any) => Promise<void>) => void;
}

const contextDefaultValues: ModalManagerContextValue = {
  getModalState: (() => {}) as any,
  subscribeModalSubmit: (() => {}) as any,
  unsubscribeModalSubmit: (() => {}) as any,
}

const ModalManagerContext = createContext<ModalManagerContextValue>(contextDefaultValues);

export const useModalManagerProvider = (): ModalManagerContextValue => {
  const context = useContext(ModalManagerContext);

  if (!context) {
    throw new Error("hook must be used within a ModalManagerProvider");
  }

  return context;
};

export enum ModalName {
  CREATE_STORE_MODAL = 'create-store-modal',
}

const modalsList: Array<{modalName: ModalName, component: FC<ModalPropsType<any>>, otherProps: { modalProps: Omit<ModalProps, 'opened' | 'onClose'>, onSubmit?: (value: any) => void } }> = [
  {
    modalName: ModalName.CREATE_STORE_MODAL,
    component: CreateStoreModal,
    otherProps: {
      modalProps: {
        centered: true,
        title: 'Create store',
        size: 'xl',
        // fullScreen: true,
        transitionProps: { transition: 'fade', duration: 400 }
      },
    }
  }
];

type ModalStateMapType = {[key in ModalName]: readonly [boolean, {
  readonly open: () => void;
  readonly close: () => void;
  readonly toggle: () => void;
}]}

export const ModalManagerProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const modalStatesMap: ModalStateMapType = {
    [ModalName.CREATE_STORE_MODAL]: useDisclosure(),
  };

  const getModalState = (modalName: ModalName) => {
    return modalStatesMap[modalName];
  };

  const submitSubscribers = useRef<{[key in ModalName]: Array<(submitValue: any) => Promise<void>>}>({
    [ModalName.CREATE_STORE_MODAL]: [],
  });

  const subscribeModalSubmit = useCallback((modalName: ModalName, subscriber: (submitValue: any) => Promise<void>) => {
    submitSubscribers.current[modalName].push(subscriber);
  }, []);

  const unsubscribeModalSubmit = useCallback((modalName: ModalName, subscriber: (submitValue: any) => Promise<void>) => {
    submitSubscribers.current[modalName] = submitSubscribers.current[modalName].filter((s) => s !== subscriber);
  }, []);

  const [submittingFormsNames, setSubmittingFormNames] = useState<Array<ModalName>>([]);

  const onSubmit = async (submitValue: any, modalName: ModalName) => {
    setSubmittingFormNames((prev) => [...prev, modalName]);

    await Promise.all(submitSubscribers.current[modalName].map((subscriberFunc) => subscriberFunc(submitValue)));

    setSubmittingFormNames((prev) => prev.filter((formName) => formName !== modalName));
  };

  return (
    <ModalManagerContext.Provider 
      value={{
        getModalState,
        subscribeModalSubmit,
        unsubscribeModalSubmit,
      }}
    >
      {children}
      <>
        {modalsList.map(({ modalName, component, otherProps }) => {
          const [opened, { close }] = getModalState(modalName);
          const isFormSubmitting = submittingFormsNames.some((submittingFormName) => submittingFormName === modalName)
          return (
            createElement(
              component, 
              {
                modalProps: {
                  opened,
                  onClose: close,
                  ...otherProps.modalProps,
                },
                isSubmitting: isFormSubmitting,
                onSubmit: (submitValue) => onSubmit(submitValue, modalName),
                key: modalName
              }
            )
          );
        })}
      </>
    </ModalManagerContext.Provider>
  );
});
