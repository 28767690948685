import React from "react";
import { ReactGrid, Column, Row, CellChange } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import Persona from "../../models/persona"; // Assuming you have a Persona model

// Assuming Persona model has a unique identifier 'persona_id'

const getPersonaColumns = (): Column[] => [
    { columnId: 'select', width: 50 }, // Column for selection checkboxes
    { columnId: 'persona_id', width: 100 },
    { columnId: 'persona_name', width: 200 },
    { columnId: 'persona_description', width: 300 },
    { columnId: 'persona_image_url', width: 200 },
    { columnId: 'persona_trends', width: 200 },
];

// Updated interface to include selection state management
interface PersonaGridProps {
    personas: Persona[];
    selectedPersonas: Persona[];
    setSelectedPersonas: (selectedPersonas: Persona[]) => void;
}

const PersonaGrid: React.FC<PersonaGridProps> = ({ personas, selectedPersonas, setSelectedPersonas }) => {

    const getPersonaRows = (): Row[] => {
        const headerRow: Row = {
            rowId: 'header',
            cells: [
                { type: 'header', text: 'Select' },
                { type: 'header', text: 'ID' },
                { type: 'header', text: 'Name' },
                { type: 'header', text: 'Description' },
                { type: 'header', text: 'Image URL' },
                { type: 'header', text: 'Trends' },
            ]
        };

        return [
            headerRow,
            ...personas.map<Row>((persona, idx) => ({
                rowId: persona.persona_id?.toString()  ?? "",
                cells: [
                    { type: 'checkbox', checked: selectedPersonas.includes(persona), disabled: false },
                    { type: 'text', text: persona.persona_id?.toString() ?? ""},
                    { type: 'text', text: persona.persona_name?.toString() ?? ""},
                    { type: 'text', text: persona.persona_description?.toString() ?? ""},
                    { type: 'text', text: persona.persona_image_url?.toString() ?? ""},
                    { type: 'text', text: persona.persona_trends?.toString() ?? ""},
                ]
            }))
        ];
    };

    const rows = getPersonaRows();
    const columns = getPersonaColumns();

    const handleCellChanges = (changes: CellChange[]) => {
        changes.forEach(change => {
            // Check if the newCell is a CheckboxCell before accessing 'checked'
            if ('checked' in change.newCell) {
                // Now TypeScript knows change.newCell has a 'checked' property
                const personaId = change.rowId.toString();
                const isSelected = change.newCell.checked;

                const newSelectedPersonas = isSelected ? [...selectedPersonas, personas.find(p => p.persona_id?.toString() === personaId)!] : selectedPersonas.filter(p => p.persona_id?.toString() !== personaId);
                setSelectedPersonas(newSelectedPersonas);
            }
        });
    };
    


    return (
        <div>
            <ReactGrid rows={rows} columns={columns} onCellsChanged={handleCellChanges}
                       enableColumnSelection enableRowSelection enableFillHandle enableRangeSelection />
        </div>
    );
}

export default PersonaGrid;
