import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import Product from "src/models/product";
import Persona from "src/models/persona";
import { axiosClient } from "./axios-client";
import { Supplier } from "src/models/supplier";
import { DbUser } from "src/models/user";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const savePersonas = async (
  accessToken: string,
  personas: Persona[]
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/save-personas`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      personas,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
}
export const getProductData = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/product-data`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getPersonasData = async (
  accessToken: string
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/get-all-personas`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const refreshDatabase = async (
  accessToken: string,
  callback: (artwork: any) => void,
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/refresh-database`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
    });

    if (response.body) {
      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        let lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach(line => {
          if (line) {
            // replace single quotes with double quotes
            
            //line = line.replace(/'/g, '"');
            const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
                console.log(line);
                trend = JSON.parse(line);
            } catch (e) {
                console.log(e);
                trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (migth be lower than the nuber of the request because some will be filtered, find a way to check), call the callbackDone function

  } catch (e) {
    console.error('Error fetching created:', e);
    // Handle errors or call the callback with an error if needed
  }
};


export const syncProductsOnAmazon = async (
  accessToken: string,
  selectedProducts: Product[],
  callback: (artwork: any) => void,
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/sync-product-templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        'product_template_ids': selectedProducts.map((p) => p.id),
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        let lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach(line => {
          if (line) {
            // replace single quotes with double quotes
            
            //line = line.replace(/'/g, '"');
            const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
                console.log(line);
                trend = JSON.parse(line);
            } catch (e) {
                console.log(e);
                trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (migth be lower than the nuber of the request because some will be filtered, find a way to check), call the callbackDone function

  } catch (e) {
    console.error('Error fetching created:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const getTrendsFromNiches = async (
  accessToken: string,
  selectedNiches: string[],
  callback: (trend: any) => void,
  callbackDone: (num: number) => void
): Promise<void> => {
  try {
    const response = await fetch(`${apiServerUrl}/api/get-tiktok-hashtags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        'niches': selectedNiches,
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: Uint8Array) => {
        buffer += decoder.decode(chunk, { stream: true });
        let lines = buffer.split('\n\n\n');
        const lastLine = lines.pop(); // Last line might be incomplete
        buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
        lines.forEach(line => {
          if (line) {
            // replace single quotes with double quotes
            
            //line = line.replace(/'/g, '"');
            const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            line = line.replace(regex, '"');
            let trend = {};
            try {
                trend = JSON.parse(line);
            } catch (e) {
                console.log(e);
                trend = JSON.parse(JSON.stringify(line));
            }
            callback(trend); // Process each complete JSON object
          }
        });
      };

      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          // Process any remaining text in the buffer
          if (buffer) {
            processChunk(new Uint8Array());
          }
          reader.releaseLock();
          return;
        }
        processChunk(value);
        read(); // Recursive call to process the next chunk
      };

      read(); // Start processing
    }
    // If all the trends have been processed (migth be lower than the nuber of the request because some will be filtered, find a way to check), call the callbackDone function
    
    


  } catch (e) {
    console.error('Error fetching trends:', e);
    // Handle errors or call the callback with an error if needed
  }
};

export const getPersonas = async (
  accessToken: string,
  selectedTrend: any
): Promise<ApiResponse> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-persona`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      data: {
        'trends': selectedTrend,
      },
    };

    const { data, error } = await callExternalApi({ config }) as ApiResponse;
    return { data, error };
  } catch (e : any) {
    return { data: null, error: e.message };
  }
};

export const getCollectionsFromTrends = async (
    accessToken: string,
    selectedTrends: any[]
  ): Promise<ApiResponse> => {
    try {
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/make-textual-artwork`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        data: {
          'trends': selectedTrends,
        },
      };
  
      const { data, error } = await callExternalApi({ config }) as ApiResponse;
      return { data, error };
    } catch (e : any) {
      return { data: null, error: e.message };
    }
  };

  export const getArtworksFromTrends = async (
    accessToken: string,
    selectedTrends: any[]
  ): Promise<ApiResponse> => {
    try {
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/make-image-artwork`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        data: {
          'trends': selectedTrends,
        },
      };
  
      const { data, error } = await callExternalApi({ config }) as ApiResponse;
      return { data, error };
    } catch (e : any) {
      return { data: null, error: e.message };
    }
  };

  
  export const createProductTemplates = async (
    accessToken: string,
    selectedArtworks: any[],
    supliers: string[],
    callback: (artwork: any) => void,
  ): Promise<void> => {
    try {
      const response = await fetch(`${apiServerUrl}/api/create-product-templates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          'image_urls': selectedArtworks,
          'suppliers': supliers,
        }),
      });
  
      if (response.body) {
        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let buffer = '';
  
        const processChunk = (chunk: Uint8Array) => {
          buffer += decoder.decode(chunk, { stream: true });
          let lines = buffer.split('\n\n\n');
          const lastLine = lines.pop(); // Last line might be incomplete
          buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
          lines.forEach(line => {
            if (line) {
              // replace single quotes with double quotes
              
              //line = line.replace(/'/g, '"');
              const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
              line = line.replace(regex, '"');
              let trend = {};
              try {
                  console.log(line);
                  trend = JSON.parse(line);
              } catch (e) {
                  console.log(e);
                  trend = JSON.parse(JSON.stringify(line));
              }
              callback(trend); // Process each complete JSON object
            }
          });
        };
  
        const read = async () => {
          const { done, value } = await reader.read();
          if (done) {
            // Process any remaining text in the buffer
            if (buffer) {
              processChunk(new Uint8Array());
            }
            reader.releaseLock();
            return;
          }
          processChunk(value);
          read(); // Recursive call to process the next chunk
        };
  
        read(); // Start processing
      }
      // If all the trends have been processed (migth be lower than the nuber of the request because some will be filtered, find a way to check), call the callbackDone function
  
    } catch (e) {
      console.error('Error fetching created:', e);
      // Handle errors or call the callback with an error if needed
    }
  };

  export const updateAttributes = async (
    accessToken: string,
    selectedProducts: Product[]
  ): Promise<ApiResponse> => {
    try {
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/update-amazon-attributes`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        data: {
          'ids': selectedProducts.map((p) => p.id),
        },
      };
  
      const { data, error } = await callExternalApi({ config }) as ApiResponse;
      return { data, error };
    } catch (e : any) {
      return { data: null, error: e.message };
    }
  };
  

  export const getAudiences = async (
    accessToken: string,
    selectedPersona: Persona,
  ): Promise<ApiResponse> => {
    try {
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/get-target-audiences`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        data: {
          'trends': selectedPersona.persona_trends,
        },
      };
  
      const { data, error } = await callExternalApi({ config }) as ApiResponse;
      return { data, error };
    } catch (e : any) {
      return { data: null, error: e.message };
    }
  };

  export const startArtworksGeneration = async (
    accessToken: string,
    targetAudience: any,
    psdTemplate: string,
    model: string,
  ) => {
    try {
      const response = await fetch(`${apiServerUrl}/api/artwork`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          'target_audiences': [targetAudience],
          'psd_template': psdTemplate,
          'image_model': model,
        }),
      });

      return {
        ...(await response.json()),
        targetAudience: {
          group_name: targetAudience.group_name,
          group_identified_queries: targetAudience.group_identified_queries,
          group_description: targetAudience.group_description,
          limit: targetAudience.limit,
        }
      };
    } catch (error) {
      console.error('Error fetching trends: ', error);
    }
  };

  export const deleteArtwork: (accessToken: string, taskId: string) => Promise<{ message: string }> = async (
    accessToken,
    taskId,
  ) => {
    try {
      const response = await fetch(`${apiServerUrl}/api/artwork/${taskId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
      });

      return response.json();
    } catch (error) {
      console.error('Error deleting artwork: ', error);
    }
  };

  export const getArtworksFromAudiences = async (
    accessToken: string,
    targetAudiences: any[],
    psdTemplate: string,
    model: string,
    callback: (artwork: any) => void,
  ): Promise<void> => {
    try {
      const response = await fetch(`${apiServerUrl}/api/get-artwork`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          'target_audiences': targetAudiences,
          'psd_template': psdTemplate,
          'image_model': model,
        }),
      });
  
      if (response.body) {
        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let buffer = '';
  
        const processChunk = (chunk: Uint8Array) => {
          buffer += decoder.decode(chunk, { stream: true });
          let lines = buffer.split('\n\n\n');
          const lastLine = lines.pop(); // Last line might be incomplete
          buffer = lastLine !== undefined ? lastLine : ''; // Save it for later, or default to empty string if undefined
          lines.forEach(line => {
            if (line) {
              // replace single quotes with double quotes
              
              //line = line.replace(/'/g, '"');
              const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
              line = line.replace(regex, '"');
              let trend = {};
              try {
                  console.log(line);
                  trend = JSON.parse(line);
              } catch (e) {
                  console.log(e);
                  trend = JSON.parse(JSON.stringify(line));
              }
              callback(trend); // Process each complete JSON object
            }
          });
        };
  
        const read = async () => {
          const { done, value } = await reader.read();
          if (done) {
            // Process any remaining text in the buffer
            if (buffer) {
              processChunk(new Uint8Array());
            }
            reader.releaseLock();
            return;
          }
          processChunk(value);
          read(); // Recursive call to process the next chunk
        };
  
        read(); // Start processing
      }
      // If all the trends have been processed (migth be lower than the nuber of the request because some will be filtered, find a way to check), call the callbackDone function
  
    } catch (e) {
      console.error('Error fetching trends:', e);
      // Handle errors or call the callback with an error if needed
    }
  };

  export const deleteProducts = async (
    accessToken: string,
    selectedProducts: Product[]
  ): Promise<ApiResponse> => {
    try {
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/delete-products`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        data: {
          'products_ids': selectedProducts.map((p) => p.id),
        },
      };
  
      const { data, error } = await callExternalApi({ config }) as ApiResponse;
      return { data, error };
    } catch (e : any) {
      return { data: null, error: e.message };
    }
  }
  export const deletePersonas = async (
    accessToken: string,
    personas: Persona[]
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/delete-personas`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        "personas": personas.map((p) => p.persona_id),
      },
    };
  
    const { data, error } = (await callExternalApi({ config })) as ApiResponse;
  
    return {
      data,
      error,
    };
  }

  export const getPsdArtworkTemplates = async (
    accessToken: string
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-psd-artwork-templates`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
      data,
      error,
    };
  }


  export const getGoogleTrendsDaily = async (
    accessToken: string,
    country: string,
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-google-trends-daily`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        "country": country,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
      data,
      error,
    };
  }

  export const getGoogleTrendsQueryData = async (
    accessToken: string,
    query: string,
    country: string,
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-google-trends-query-data`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        "keyword": query,
        "country": country,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
      data,
      error,
    };
  }

  export const filterGeneralTrends = async (
    accessToken: string,
    trends: any[],
    country: string,
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/filter-general-trends`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        "trends": trends,
        "country": country,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
      data,
      error,
    };
  }

  export const getAudienceGroups = async (
    accessToken: string,
    trend: any,
  ): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/get-audience-groups`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        "trend": trend,
      },
    };

    const { data, error } = (await callExternalApi({ config })) as ApiResponse;

    return {
      data,
      error,
    };
  }
  
export const getUsers = async (
  accessToken: string,
) => {
  try {
    const response = await axiosClient.get<Array<DbUser>>('/user-data', {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error){
    throw error;
  }
};

export const getSuppliers = async (
  accessToken: string,
) => {
  try {
    const response = await axiosClient.get<Array<Supplier>>(
      '/supplier-data',
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error){
    throw error;
  }
};

export const createStore = async (
  data: {
    supplier_names: Array<string>,
    supplier_api_keys: Array<string>,
    user_id: string,
  }
) => {
  try {
    const response = await axiosClient.post<{ message: string }>('/users', data);

    return response.data;
  } catch (error){
    throw error;
  }
};
