import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";

interface NicheContextProps {
  selectedNiches: string[];
  setSelectedNiches: Dispatch<SetStateAction<string[]>>;
}

const NicheContext = createContext<NicheContextProps | undefined>(undefined);

interface NicheProviderProps {
  children: ReactNode;
}

export const useNiches = (): NicheContextProps => {
  const context = useContext(NicheContext);
  if (!context) {
    throw new Error("useNiches must be used within a NicheProvider");
  }
  return context;
};

export const NicheProvider: React.FC<NicheProviderProps> = ({ children }) => {
  const [selectedNiches, setSelectedNiches] = useState<string[]>([]);
  return (
    <NicheContext.Provider value={{ selectedNiches, setSelectedNiches }}>
      {children}
    </NicheContext.Provider>
  );
};
