import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

interface PrintfulContextProps {
    cookies: string[];
    headers: string[];
    setCookies: Dispatch<SetStateAction<string[]>>;
    setHeaders: Dispatch<SetStateAction<string[]>>;
  }
  
  const PrintfulContext = createContext<PrintfulContextProps | undefined>(undefined);

export const PrintfulProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [cookies, setCookies] = useState<string[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    
    return (
      <PrintfulContext.Provider value={{ cookies, setCookies, headers, setHeaders }}>
        {children}
      </PrintfulContext.Provider>
    );
  };

  export const usePrintful = (): PrintfulContextProps => {
    const context = useContext(PrintfulContext);
    if (!context) {
      throw new Error("usePrintful must be used within a PrintfulProvider");
    }
    return context;
  };