  import React, { CSSProperties, useRef, useEffect } from 'react';
  import { useAuth0 } from "@auth0/auth0-react";

  import { Button, Paper, Text, Title, Box, Image, UnstyledButton, Checkbox } from '@mantine/core';
  import { Carousel } from '@mantine/carousel';
  import { usePersonas } from '../../selected-personas-provider';
  import { getPersonas } from 'src/services/automation.service';
  import { savePersonas } from 'src/services/automation.service';
  import plusicon from '../../assets/icons/plus-icon.svg';
  import trashicon from '../../assets/icons/trash-icon.svg';
  import { useNavigate } from "react-router-dom";



  interface PersonaViewProps {
    trends: any[];
    resetStepper: () => void;
    selectedAudienceGroups: any[];
  }

  export const PersonaView: React.FC<PersonaViewProps> = ({ trends, resetStepper, selectedAudienceGroups }) => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const accessToken = useRef<string>();
    const { personas, setPersonas } = usePersonas();

    useEffect(() => {
      let isMounted = true;
      if (personas.length !== 0) {
        setPersonas([]);
      }
      const getAccessToken = async () => {
        if (!isMounted) {
          return;
        }
        try {
          const token = await getAccessTokenSilently();
          accessToken.current = token;
        } catch (e) {
          console.error(e);
        }
      };
      for (let i = 0; i < trends.length; i++) {
        setPersonas(prevPersonas => {
          return [...prevPersonas, Array(selectedAudienceGroups[i].audienceGroups.groups.length).fill({ name: 'UNFETCHED', loading: true })];
        });
      }
      for (let i = 0; i < trends.length; i++) {
        for (let j = 0; j < selectedAudienceGroups[i].audienceGroups.groups.length; j++) {
          handleFetchPersona(i, j);
        }
      }
      getAccessToken();
      return () => {
        isMounted = false;
      };
    }, [getAccessTokenSilently, setPersonas, personas.length, trends]);


    const handleFetchPersona = async (trendIdx: number, personaIdx: number) => {
      if (accessToken.current) {
        const { data } = await getPersonas(accessToken.current, selectedAudienceGroups[trendIdx].audienceGroups.groups[personaIdx]);
        if (data) {
          // if the data is not a string, convert it to a string
          let realdata = data;
          if (typeof data !== 'string') {
            realdata = JSON.stringify(data);
          }
          // cut the data from the first { to the last }
          let sliced = realdata.slice(realdata.indexOf('{'), realdata.lastIndexOf('}') + 1);
          const regex = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
          sliced = sliced.replace(regex, '"');
          const parsed = JSON.parse(sliced);
          // add the trend object to the persona object at persona.persona_trends
          parsed.persona_trends = JSON.stringify(selectedAudienceGroups[trendIdx].audienceGroups.groups[personaIdx]);
          // add the persona description (all fields displayed to the user) to the persona object at persona.persona_description
          parsed.persona_description = JSON.stringify(parsed);
          setPersonas(prevPersonas => {
            const updatedPersonas = [...prevPersonas];
            updatedPersonas[trendIdx][personaIdx] = parsed;
            return updatedPersonas;
          });
        }
      }
    };

    const handleSavePersonas = async () => {
      if (accessToken.current) {
        const selectedPersonas = personas.flat().filter(persona => persona.checked);
        const { data } = await savePersonas(accessToken.current, selectedPersonas.flat());

        resetStepper();
      }
    };

    
    // Define inline styles with explicit types
    // Define personas

    // Define inline styles with explicit types
    const styles: Record<string, CSSProperties> = {
      swiperContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        
      },
      personaPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '100%',
        height: '600px',
      },
      paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '50px',
        zIndex: 100,
      },
      pageTitle: {
        marginBottom: '10px',
      },
      personName: {
        marginBottom: '20px',
        color: '#000000',
      },
      continueButton: {
        cursor: 'pointer',
      },
    };

    return (
      <div>
          
      {trends.map((trend, trendIdx) => (
        <div key={trendIdx} >
        <Text style={{color: '#ffffff', fontSize: '48px', fontWeight: 400, height: "20%"}}>
          {"#" + trend.query}
        </Text>
        <Carousel
          style={styles.swiperContainer}
          withIndicators
          slideSize="33.333333%"
          slideGap="md"
          align="start"
          slidesToScroll={3}
        >
            {personas.length !== 0 && personas[trendIdx].map((persona, personaIdx) => {
              if (persona.name === 'UNFETCHED' || persona.name === 'FETCHING') {
                return (
                <Carousel.Slide key={personaIdx}>
                  <Box style={styles.personaPageContainer}>
                  <Checkbox color="blue" checked={persona.checked || false} size='xl' disabled/>
                    <Paper style={styles.paperContainer} >
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Image src={trashicon} alt="trash icon" width={30} height={30} style={{padding: 2}} onClick={(event) => {
                        event.stopPropagation();
                        setPersonas(prevPersonas => {
                          return prevPersonas.map((trendPersonas, index) => {
                            if (index === trendIdx) {
                              // Ensure there are more than two personas (including "ADD MORE") before allowing deletion
                              if (trendPersonas.length > 2) {
                                return trendPersonas.filter((_, pidx) => pidx !== personaIdx);
                              } else {
                                // Optionally, alert the user that the last persona cannot be deleted
                                alert("Cannot delete the last persona. At least one persona is required.");
                              }
                            }
                            return trendPersonas;
                          });
                        });
                      }}/>
                      </Box>
                      <Box style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Title order={1} style={styles.personName} className="person-name">{persona.name}</Title>
                      </Box>
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                          variant="filled"
                          color="dark"
                          size="md"
                          style={styles.continueButton}
                          className="continue-button"
                          loading={persona.loading}
                          onClick={(event) => { event.stopPropagation();
                            // old befor array of arry setPersonas([...personas.slice(0, personaIdx), { name: 'FETCHING', loading: true }, ...personas.slice(index + 1)]); handleFetchPersona(index);}}
                            // new with array of array:
                            setPersonas([...personas.slice(0, trendIdx), [...personas[trendIdx].slice(0, personaIdx), { name: 'FETCHING', loading: true }, ...personas[trendIdx].slice(personaIdx + 1)], ...personas.slice(trendIdx + 1)]); handleFetchPersona(trendIdx, personaIdx);}}
                          >
                          FETCH PERSONA
                        </Button>
                      </Box>
                    </Paper>
                  </Box>
                </Carousel.Slide>
                )
              } else if (persona.name === 'ADD MORE'){
                return (
                <Carousel.Slide key={personaIdx}>
                  <UnstyledButton style={{width: "100%", height: "200px",}} onClick={() => {
                    //add an empty persona to the personas array before the last element which is the add more button
                    // old befor array of arry setPersonas([...personas.slice(0, -1), { name: 'UNFETCHED', loading: false }, { name: 'ADD MORE' }]);
                    // new with array of array:
                    setPersonas([...personas.slice(0, trendIdx), [...personas[trendIdx].slice(0, -1), { name: 'UNFETCHED', loading: false }, { name: 'ADD MORE' }], ...personas.slice(trendIdx + 1)]);
                  } }>
                  <Box style={styles.personaPageContainer}>
                  <Checkbox color="blue" checked={persona.checked || false} size='xl' disabled/>
                    <Paper style={styles.paperContainer}>
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image src={plusicon} alt="add icon" width={100} height={100} style={{padding: 10}}/>
                        <Title order={1} style={styles.personName} className="person-name">{persona.name}</Title>
                      </Box>
                    </Paper>
                  </Box>
                  </UnstyledButton>
                </Carousel.Slide>
                )
              } else  {
                return (
                  <Carousel.Slide key={personaIdx}>
                    <Box style={styles.personaPageContainer}>
                    <Checkbox color="blue" checked={persona.checked || false} size='xl' />
                      <Paper style={styles.paperContainer} onClick={() => {
                    // set the persona to checked true or false acdording to the current value
                    if (persona.checked) {
                      persona.checked = false;
                      // old befor array of arry setPersonas([...personas.slice(0, index), persona, ...personas.slice(index + 1)]); 
                      // new with array of array:
                      setPersonas([...personas.slice(0, trendIdx), [...personas[trendIdx].slice(0, personaIdx), persona, ...personas[trendIdx].slice(personaIdx + 1)], ...personas.slice(trendIdx + 1)]);
                    } else {
                      persona.checked = true;
                      // old befor array of arrysetPersonas([...personas.slice(0, index), persona, ...personas.slice(index + 1)]);
                      // new with array of array:
                      setPersonas([...personas.slice(0, trendIdx), [...personas[trendIdx].slice(0, personaIdx), persona, ...personas[trendIdx].slice(personaIdx + 1)], ...personas.slice(trendIdx + 1)]);
                    }
                  }
                  }>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Image src={trashicon} alt="trash icon" width={30} height={30} style={{padding: 2}} onClick={(event) => {
                          event.stopPropagation();
                          setPersonas(prevPersonas => {
                            return prevPersonas.map((trendPersonas, index) => {
                              if (index === trendIdx) {
                                // Ensure there are more than two personas (including "ADD MORE") before allowing deletion
                                if (trendPersonas.length > 2) {
                                  return trendPersonas.filter((_, pidx) => pidx !== personaIdx);
                                } else {
                                  // Optionally, alert the user that the last persona cannot be deleted
                                  alert("Cannot delete the last persona. At least one persona is required.");
                                }
                              }
                              return trendPersonas;
                            });
                          });
                        }}/>
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Title order={1} style={styles.personName} className="person-name">{persona.persona_name}</Title>
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Age: " + persona.age}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Gender: " + persona.gender}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Interests: " + persona.interests}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Habits: " + persona.habits}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Social Media: " + persona.social_media}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"T-Shirt Preferences: " + persona.tshirt_preferences}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Target Audience: " + persona.target_audience}
                          </Text>
                          <Text style={{color: '#000000', fontSize: '16px', fontWeight: 400, height: "20%"}}>
                            {"Hashtags: " + persona.hashtags}
                          </Text>
                        </Box>
                      </Paper>
                    </Box>
                  </Carousel.Slide>
                )
              }
            })}
          </Carousel>
        </div>
        ))}
        <div style={{ position: "sticky", right: "0px", bottom: "0px", width: "100%", height: "100px",  display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Button onClick={async () => {
                const selectedPersonas = personas.flat().filter(p => p.checked);
                if (selectedPersonas.length === 0) {
                  alert("Please select at least one persona");
                } else {
                  await handleSavePersonas();
                  navigate("/personas-dashboard");
                }
              }} size="l">Save Personas To database</Button>
            </div>
      </div>
    );
  };

  export default PersonaView;