import { FC, PropsWithChildren, createContext, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { WebSocketContextType } from "./types/context-type";
import { WebSocketClient } from "./web-socket-client";

const initialValue: WebSocketContextType = {
  websocketClient: {} as any,
  isConnected: false,
};

const Context = createContext<WebSocketContextType>(initialValue);

export const WebSocketContext: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const ref = useRef<WebSocketClient>();
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const instance = new WebSocketClient();
    ref.current = instance;

    const onOpen = (e: Event) => {
      setIsConnected(true);
      console.log(e);
    };

    const onClose = (e: CloseEvent) => {
      setIsConnected(false);
      console.log(e);
    };

    const onError = (e: Event) => {
      setIsConnected(false);
      console.error(e);
    };

    instance.webSocketInstance.addEventListener('open', onOpen);
    instance.webSocketInstance.addEventListener('close', onClose);
    instance.webSocketInstance.addEventListener('error', onError);

    return () => {
      instance.webSocketInstance.close();
      instance.webSocketInstance.removeEventListener('open', onOpen);
      instance.webSocketInstance.removeEventListener('close', onClose);
      instance.webSocketInstance.removeEventListener('error', onError);
    }
  }, []);

  const contextValue = useMemo(() => ({
    websocketClient: ref.current as WebSocketClient,
    isConnected
  }), [isConnected]);

  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
});

export const useWebsocketClient = () => useContext(Context);