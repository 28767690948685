import { RouteProps } from "react-router-dom";
import { AuthenticationGuard } from "src/components/authentication-guard";
import { CallbackPage } from "src/pages/callback-page";
import { HomePage } from "src/pages/home-page";
import { NotFoundPage } from "src/pages/not-found-page";
import { PersonaCreatorPage } from "src/pages/persona-creator-page";
import { PersonaDashboard } from "src/pages/personas-dashboard-page";
import { ProductsCreatorPage } from "src/pages/products-creator-page";
import { ProductsDashboard } from "src/pages/products-dashboard-page";
import { UserHome } from "src/pages/user-home";
import { UserSettings } from "src/pages/settings-page";

export const appRoutesDescription: Array<RouteProps> = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/user-home',
    element: <AuthenticationGuard component={UserHome} />
  },
  {
    path: '/settings',
    element: <AuthenticationGuard component={UserSettings} />
  },
  {
    path: '/products-dashboard',
    element: <AuthenticationGuard component={ProductsDashboard} />
  },
  {
    path: '/personas-dashboard',
    element: <AuthenticationGuard component={PersonaDashboard} />
  },
  {
    path: '/persona-creator',
    element: <AuthenticationGuard component={PersonaCreatorPage} />
  },
  {
    path: '/products-creator',
    element: <AuthenticationGuard component={ProductsCreatorPage} />
  },
  {
    path: '/callback',
    element: <CallbackPage />
  },
  {
    path: '*',
    element: <NotFoundPage />
  },
];