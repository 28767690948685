import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";
import Persona from "./models/persona";

interface PersonaContextProps {
    personas: Persona[][];
    setPersonas: Dispatch<SetStateAction<Persona[][]>>;
}

const PersonaContext = createContext<PersonaContextProps | undefined>(undefined);

interface PersonaProviderProps {
    children: ReactNode;
}

export const usePersonas = (): PersonaContextProps => {
    const context = useContext(PersonaContext);
    if (!context) {
        throw new Error("usePersonas must be used within a PersonaProvider");
    }
    return context;
};

export const PersonaProvider: React.FC<PersonaProviderProps> = ({ children }) => {
    const [personas, setPersonas] = useState<Persona[][]>([]);
    return (
        <PersonaContext.Provider value={{ personas, setPersonas }}>
            {children}
        </PersonaContext.Provider>
    );
};
