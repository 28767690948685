import { User, useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { 
  createContext,
  useContext,
  FC,
  memo,
  PropsWithChildren,
  useMemo,
} from "react";
import { createStore, getSuppliers, getUsers } from "./services/automation.service";
import { DbUser } from "./models/user";

interface SettingsPageContextValue {
  users?: any;
  targetUserFromDb?: DbUser;
  authorizedUser?: User;
  isUsersFetching: boolean;
  isUserFetching: boolean;
  createUser: (
    data: {
      supplier_names: string[];
      supplier_api_keys: string[];
      user_id: string;
    }) => Promise<{ message: string }>;
  targetUserSuppliers?: Array<{
    name: string;
    apiKey: string;
  }>;
}

const contextDefaultValues = {
  isUsersFetching: true,
  isUserFetching: true,
  createUser: (() => {}) as any,
}

const SettingsPageContext = createContext<SettingsPageContextValue>(contextDefaultValues);

export const useSettingsPageContext = (): SettingsPageContextValue => {
  const context = useContext(SettingsPageContext);

  if (!context) {
    throw new Error("usePersonas must be used within a PersonaProvider");
  }

  return context;
};

export const SettingsPageProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { user, isLoading: isUserFetching, getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { data: users, isFetching: isUsersFetching, isLoading: isUsersLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => getUsers(await getAccessTokenSilently())
  });

  const targetUserFromDb = useMemo(() => {
    if (!users || !user || !Array.isArray(users)) {
      return;
    }

    return users.find(({ user_id }) => user_id === user.email);
  }, [user, users]);

  const { 
    data,
    isFetching: isSuppliersFetching,
    isLoading: isSuppliersLoading,
    // refetch: refetchSuppliers 
  } = useQuery({
    queryKey: ['suppliers'],
    queryFn: async () => getSuppliers(await getAccessTokenSilently())
  });

  const suppliers = useMemo(() => data || [], [data]);

  const targetUserSuppliers = useMemo(() => {
    if (isSuppliersFetching || isSuppliersLoading) {
      return [];
    }

    if (!targetUserFromDb) {
      return [];
    }

    return targetUserFromDb.suppliers_id.map((supplierId, index) => {
      const supplierName = suppliers.find(({ id }) => id === supplierId)?.name || String(supplierId);

      return { name: supplierName, apiKey: targetUserFromDb.suppliers_api_keys[index] }
    })
  }, [isSuppliersFetching, isSuppliersLoading, suppliers, targetUserFromDb]);

  const { mutateAsync } = useMutation({
    mutationFn: createStore,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  })

  const contextValue = useMemo(() => ({ 
    users,
    targetUserFromDb,
    authorizedUser: user,
    isUsersFetching: isUsersFetching || isUsersLoading,
    isUserFetching: isUserFetching || isUsersFetching,
    createUser: mutateAsync,
    targetUserSuppliers
  }), [isUserFetching, isUsersFetching, isUsersLoading, mutateAsync, targetUserFromDb, targetUserSuppliers, user, users]);

  return (
    <SettingsPageContext.Provider value={contextValue}>
      {children}
    </SettingsPageContext.Provider>
  );
});
