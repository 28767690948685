import React, { useState, useEffect, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react"
import { Paper, Text, MultiSelect, Select,  Box, NumberInput, Radio, Group } from '@mantine/core';
import { getPersonasData, getPsdArtworkTemplates } from "../../services/automation.service";  // Assuming you have a getPersonaData function
import Persona from "../../models/persona";  // Assuming you have a Persona model
import { useQuery } from '@tanstack/react-query';


// define trend view props
interface TargetAudienceViewProps {
  targetAudiences: any[];
  setTargetAudiences: (targetAudiences: any[]) => void;
  artworkPerTargetAudience: number[];
  setArtworkPerTargetAudience: (artworkPerTargetAudience: number[]) => void;
  selectedPsdTemplate: string;
  setSelectedPsdTemplate: (selectedPsdTemplate: string) => void;
  selectedModel: string;
  setSelectedModel: (selectedModel: string) => void;
  selectedPersona: Persona[];
  setSelectedPersona: (selectedPersona: Persona[]) => void;
}
  
export const TargetAudienceView: React.FC<TargetAudienceViewProps> = ({
  setTargetAudiences,
  artworkPerTargetAudience,
  setArtworkPerTargetAudience,
  selectedPsdTemplate,
  setSelectedPsdTemplate,
  selectedModel,
  setSelectedModel,
  selectedPersona,
  setSelectedPersona
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [multiValue, setMultiValue] = useState<string[]>([]);  // State to hold selected persona
  const [templateBase, setTemplateBase] = useState('artwork_white'); // New state for base template selection
  const [templateVariant, setTemplateVariant] = useState(''); // New state for variant selection
  const [variants, setVariants] = useState<string[]>([]); // Variants for the selected base

  const personasQuery = useQuery({
    queryKey: ['personas'],
    queryFn: async () => {
      return getPersonasData(await getAccessTokenSilently());
    }
  });
  
  const personas = useMemo(() => {
    if (personasQuery.isSuccess) {
      return personasQuery.data.data.map((persona: any) => 
        Object.fromEntries(Object.entries(persona).filter(
          ([key]) => !key.startsWith('__') && !key.startsWith('_'))
        )
      );
    }

    return [];
  }, [personasQuery.isSuccess]);

  const psdQuery = useQuery({
    queryKey: ['psd'],
    queryFn: async () => {
      return getPsdArtworkTemplates(await getAccessTokenSilently());
    }
  });
  const [psdLoadFirst, setPsdLoadFirst] = useState(false);

  useEffect(() => {
    if (psdLoadFirst) {
      return;
    }

    if (psdQuery.isSuccess) {
      setPsdLoadFirst(true);

      if (selectedPsdTemplate === "" && psdQuery.data.data && psdQuery.data.data.length) {
        setSelectedPsdTemplate(psdQuery.data.data[0]);
      }
    }

    return () => {
      setPsdLoadFirst(false);
    }
  }, [psdLoadFirst, psdQuery.data, psdQuery.isSuccess, selectedPsdTemplate, setSelectedPsdTemplate]);

  // useEffect(() => {
  //   const getPsdTemplates = async () => {
  //     const accessToken = await getAccessTokenSilently();
  //     const { data, error } = await getPsdArtworkTemplates(accessToken);
  
  //     if (data && data.length > 0) {
  //       // Set the first template as selected if none is selected yet
  //       if (selectedPsdTemplate === "") {
  //         setSelectedPsdTemplate(data[0]);
  //       }
  //     }
  
  //     if (error) {
  //       console.error(error);
  //     }
  //   };

  //   getPsdTemplates();
  // }, [getAccessTokenSilently, selectedPsdTemplate, setSelectedPsdTemplate]);

  const handleSelectPersona = async (personaName: string[]) => {
    if (!personaName) {
      return;
    }

    setMultiValue(personaName);
    // find the newly selected persona,  persona name is a list of strings with the selected personas and selectedPersona is a list of Persona objects with attribute persona_name in the old state
    // careful about typescript type checkingArgument of type 'string | undefined' is not assignable to parameter of type 'string'.
    //Type 'undefined' is not assignable to type 'string'.ts(2345)
    //const persona = personas.filter((persona) => personaName.includes(persona.persona_name));        
    let newlySelectedPersona: Persona[] = [];

    personas.forEach((persona: any) => {
      if (persona.persona_name && personaName.includes(persona.persona_name)) {
        newlySelectedPersona.push(persona);
      }
    });

    setSelectedPersona(newlySelectedPersona);

    let newTargetAudiences: any[] = [];

    newlySelectedPersona.forEach((persona) => {
      const parsed = JSON.parse(persona.persona_trends as string);
      newTargetAudiences.push(parsed);
    });

    setTargetAudiences(newTargetAudiences);
    setArtworkPerTargetAudience(new Array(newTargetAudiences.length).fill(1));
  };

  useEffect(() => {
    if (templateBase === 'artwork_black' || templateBase === 'artwork_white') {
      setVariants(['NoSlogan_Round', 'NoSlogan_Square', 'Square', 'Star']);
    } else if (templateBase === 'artwork') {
      setVariants(['LeftCorner', 'RightCorner']);
    } else {
      setVariants([]);
    }
    // Reset variant when changing base
    setTemplateVariant('');
  }, [templateBase]);

  // Construct the full template name when a new variant is selected
  useEffect(() => {
    if (templateVariant !== '') {
      const templateName = `${templateBase}_${templateVariant}.psd`;
      setSelectedPsdTemplate(templateName);
    }
  }, [templateVariant]);

  return(
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "100%", flexDirection: "column", padding: 2}}>
      <MultiSelect
        label="Select Persona"
        placeholder="Select a persona"
        searchable
        value={multiValue}
        onChange={(value) => handleSelectPersona(value ? value : [])}
        data={personas.map((persona: any) => persona.persona_name).filter((name: string): name is string => !!name)}
        style={{padding: 20, width: 1000}}
      />
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "100%", flexDirection: "column", padding: 2}}>
        <Box>
          {/* <Select
            label="Select The Template Base: This is the shape, placement and color of the frame"
            placeholder="Select base"
            value={templateBase}
            onChange={setTemplateBase as any}
            data={[
              { value: 'artwork_black', label: 'White' },
              { value: 'artwork_white', label: 'Black' },
              { value: 'artwork', label: 'Color Independent' }
            ]}
          /> */}
          {variants.length > 0 && (
            <Select
              label="Select Template Variant"
              placeholder="Select variant"
              value={templateVariant}
              onChange={setTemplateVariant as any}
              data={variants.map(variant => ({ value: variant, label: variant.replace(/_/g, ' ') }))}
            />
          )}
        </Box>
        <Radio.Group
          name="favoriteFramework"
          label="Image Model"
          description="The model to use for generating the images. /!\ Dalle is pay per use"
          withAsterisk
        >
          <Group mt="xs">
            <Radio
              value="Dalle 3"
              color="blue"
              checked={selectedModel === "Dalle 3"}
              onChange={() => setSelectedModel("Dalle 3")}
              label="Dalle 3"
            />
            <Radio
              value="Midjourney"
              color="blue"
              checked={selectedModel === "Midjourney"}
              onChange={() => setSelectedModel("Midjourney")}
              label="Midjourney"
            />
          </Group>
        </Radio.Group>
        {selectedPersona.length > 0 && (
          <Box
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: "100%", flexDirection: "column", padding: 2}}
          >
            {selectedPersona.map((persona, index) => (
              <Paper
                shadow="sm"
                style={{ marginTop: 10, padding: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , width: "100%", height: "20%"}}
                key={index}
              >
                <Text style={{ fontSize: 20, fontWeight: "bold", color: "black", padding: 20 }} >
                  {"Number of artworks to generate for : " + persona.persona_name}
                </Text>
                <NumberInput
                  label=""
                  placeholder="Number of Artworks"
                  min={1}
                  max={10}
                  size='xl'
                  value={artworkPerTargetAudience[index]}
                  onChange={(value) => {
                    setArtworkPerTargetAudience(artworkPerTargetAudience.map((item, i) => i === index ? parseInt(value as string) : item));
                    console.log(artworkPerTargetAudience);
                  }}
                />
              </Paper>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
