import React, {useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react"
import { Text, Box, Loader, RingProgress, MultiSelect, Button } from '@mantine/core';
import { createProductTemplates } from "../../services/automation.service"; 
import { useNavigate } from "react-router-dom";



interface CreateProductTemplatesViewProps {
    selectedArtworks: any[];
}

export const CreateProductTemplatesView: React.FC<CreateProductTemplatesViewProps> = ({ selectedArtworks }) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTemplates, setLoadingTemplates] = useState<any[]>([]);
    const [loadingText, setLoadingText] = useState<string>("Logging to printful...");
    const [suppliers, setSuppliers] = useState<any[]>([]);

    useEffect(() => {
        let isMounted = true;

        return () => {
        isMounted = false;
        };
    }, [getAccessTokenSilently]);

    const createProductTemplate = async () => {
        if (suppliers.length === 0) {
            alert("Please select a supplier");
            return;
        }
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        await createProductTemplates(accessToken, selectedArtworks, suppliers, 
            (template) => {
                setLoadingTemplates(loadingTemplates => [...loadingTemplates, template]);
                console.log(loadingTemplates);
                if (template.success === false) {
                    setLoadingText(`Error creating product template ${JSON.stringify(template.artwork)}: ${template.error}`);
                }
                if (loadingTemplates.length/selectedArtworks.length === 1) {
                    navigate("/products-dashboard");
                } else {
                    setLoadingText(`Product template ${template.id} created!`);

                }
            }
        );
    };

    return (
        <Box style={{ padding: 20 }}>
        {loading ? null : 
        <div>
            <MultiSelect data={['Printify','Shirtigo', 'Teemill']} placeholder="Select supplier" value={suppliers} onChange={(value) => setSuppliers(value)} />
            <Button onClick={createProductTemplate}>Create Product Templates</Button>
            </div>}
        {loading ? 
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
            {loadingTemplates.length > 0 ? <RingProgress size={100} sections={[{
                value: loadingTemplates.length/selectedArtworks.length*100,
                color: loadingTemplates.length/selectedArtworks.length === 1 ? "green" : "blue"
            }]}/> : <Loader size={100} color="blue" />}
             
            <Text style={{ color: 'white', marginLeft: 20, fontSize: 30 }} size="lg">
            {loadingText}
            </Text>
        </Box>
        : <Text>Done!</Text>}
        </Box>
    );
};