import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from '@mantine/core';

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/user-home",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Button variant="filled" onClick={handleLogin}>
      Log In
    </Button>
  );
};
