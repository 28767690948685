import React, { useState, MouseEvent } from 'react';
import { Table, Checkbox, Image, ActionIcon, ScrollArea } from '@mantine/core';
import { IconEye, IconEyeOff, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import Product from '../../models/product';
import { useProducts } from '../../selected-products-provider';

interface ResizableThProps {
  children: React.ReactNode;
  onResize: (newWidth: number) => void;
  style: React.CSSProperties;
}
const ResizableTh: React.FC<ResizableThProps> = ({ children, onResize, ...props }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(0);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsResizing(true);
    setStartX(e.clientX);
    const th = e.currentTarget.parentElement?.closest('th');
    setStartWidth(th?.clientWidth ?? 0);
    e.preventDefault(); // Prevent text selection
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    const newWidth = startWidth + e.clientX - startX;
    onResize(newWidth);
  };

  const onMouseUp = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    if (isResizing) {
      document.body.style.cursor = 'col-resize';
      document.addEventListener('mousemove', onMouseMove as any);
      document.addEventListener('mouseup', onMouseUp);
    } else {
      document.body.style.cursor = '';
    }
  
    return () => {
      document.body.style.cursor = '';
      document.removeEventListener('mousemove', onMouseMove as any);
      document.removeEventListener('mouseup', onMouseUp);

    };
  }, [isResizing, onMouseMove, onMouseUp]);

  return (
    <th {...props} style={{ position: 'relative', ...props.style }}>
      {children}
      {/* Resize handle */}
      <div
        onMouseDown={onMouseDown}
        style={{
          cursor: 'col-resize',
          width: '10px',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          color: 'white',
          zIndex: 1, // Ensure it's above other content for mouse events
        }}
      />
    </th>
  );
};


interface ProductGridProps {
  products: Product[];
}

interface ColumnVisibility {
  [key: string]: boolean;
}

const initialColumnVisibility: ColumnVisibility = {
  select: true, // For checkbox select/deselect all
  id: true,
  name: true,
  description: true,
  category: true,
  artwork_url: true,
  artwork_prompt: false,
  theme: true,
  persona_id: true,
  variants: false,
  supplier_ids: true,
  supplier_names: true,
};
interface SortState {
  field: keyof Product | null;
  direction: 'asc' | 'desc';
}

const initialSortState: SortState = {
  field: null,
  direction: 'asc',
};

const ProductGrid: React.FC<ProductGridProps> = ({ products }) => {
  // Existing state declarations...
  const [sortState, setSortState] = useState<SortState>(initialSortState);
  const { selectedProducts, setSelectedProducts } = useProducts();
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);
  const [columnVisibility, setColumnVisibility] = useState<ColumnVisibility>(initialColumnVisibility);
  const firstWidths = Object.keys(initialColumnVisibility).reduce((acc, key) => ({ ...acc, [key]: 150 }), {});
  const [columnWidths, setColumnWidths] = useState(firstWidths as { [key: string]: number });

  const handleResize = (columnKey: string, newWidth: number) => {
    setColumnWidths(prevWidths => ({ ...prevWidths, [columnKey]: newWidth }));
  };

  const toggleColumnVisibility = (columnKey: string) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
    // Reset column width to minimum when toggling visibility
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [columnKey]: 150,
    }));
  };

  const handleSort = (field: keyof Product) => {
    setSortState((prevState) => {
      if (prevState.field === field) {
        return { ...prevState, direction: prevState.direction === 'asc' ? 'desc' : 'asc' };
      } else {
        return { field, direction: 'asc' };
      }
    });
  };

  const handleRowClick = (event: MouseEvent<HTMLTableRowElement>, product: Product, idx: number) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, idx);
      const end = Math.max(lastSelectedIndex, idx);
      const newSelectedProducts = products.slice(start, end + 1).filter((p) => !selectedProducts.includes(p));
      //if the start product is already selected, remove all selected products from the selection
      if (selectedProducts.includes(products[start])) {
        console.log('start product is already selected');
        console.log('selectedProducts', selectedProducts);
        //setSelectedProducts(selectedProducts.filter((p) => !newSelectedProducts.includes(p))); doesn't work
        setSelectedProducts(selectedProducts.filter((p) => !products.slice(start, end + 1).includes(p)));
      } else {
        setSelectedProducts([...selectedProducts, ...newSelectedProducts]);
      }
    } else {
      toggleProductSelection(product);
      setLastSelectedIndex(idx);
    }
  };

  const toggleProductSelection = (product: Product) => {
    if (selectedProducts.some((p) => p.id === product.id)) {
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const sortedProducts = React.useMemo(() => {
    if (!sortState.field) {
      return products;
    }
  
    return [...products].sort((a, b) => {
      const aValue = a[sortState.field as keyof Product];
      const bValue = b[sortState.field as keyof Product];
  
      // If either value could be null/undefined, handle that case here, for example:
      // const aValue = a[sortState.field] ?? '';
      // const bValue = b[sortState.field] ?? '';
  
      // If the field is a string, use localeCompare
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * (sortState.direction === 'asc' ? 1 : -1);
      }
      // If the field is a number, use subtraction
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue - bValue) * (sortState.direction === 'asc' ? 1 : -1);
      }
      // If the field is a boolean, use subtraction
      if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        return (Number(aValue) - Number(bValue)) * (sortState.direction === 'asc' ? 1 : -1);
      }

      return 0;
    });
  }, [products, sortState]);

  const isProductSelected = (product: Product) =>
  selectedProducts.some((p) => p.id === product.id);
  

    return (
      <ScrollArea style={{ height: "70vh" }}>
        <Table striped highlightOnHover stickyHeader style={{ tableLayout: 'fixed' }}>
        <Table.Thead>
          <Table.Tr>
            {Object.entries(columnVisibility)
              .sort((a, b) => Number(b[1]) - Number(a[1])) // Sort by visibility to render enabled columns first
              .map(([columnKey, isVisible]) => (
                <ResizableTh
                  key={columnKey}
                  style={{ width: columnWidths[columnKey] || 'auto' }}
                  onResize={(newWidth) => handleResize(columnKey, newWidth)}
                >
                  {columnKey !== 'select' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                    <div
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                      onClick={() => handleSort(columnKey as keyof Product)}
                    >
                      {columnKey.replace(/_/g, ' ').replace('artwork url', 'Artwork')}
                      <ActionIcon onClick={() => toggleColumnVisibility(columnKey)} style={{ padding: 5 }}>
                        {isVisible ? <IconEye size={16} /> : <IconEyeOff size={16} />}
                      </ActionIcon>
                    </div>
                    {sortState.field === columnKey && (
                          <>{sortState.direction === 'asc' ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}</>
                      )}
                    </div>
                  ) : (
                    <Checkbox
                      indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                      checked={selectedProducts.length === products.length && products.length > 0}
                      onChange={() => setSelectedProducts(selectedProducts.length === products.length ? [] : products)}
                    />
                  )}
                </ResizableTh>
              ))}
          </Table.Tr>
        </Table.Thead> 
          <Table.Tbody>
            {sortedProducts
            .map((product, idx) => (
              <Table.Tr key={product.id} onClick={(e) => handleRowClick(e, product, idx)} style={{
                cursor: 'pointer',
                backgroundColor: isProductSelected(product) ? 'blue' : 'transparent',
              }}>
                {Object.keys(columnVisibility).filter(k => columnVisibility[k]).map(columnKey =>
                  <Table.Td key={columnKey} style={{ width: `${columnWidths[columnKey]}px` }}>
                    <div style={{display: "flex",flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    {columnKey === 'artwork_url' && product.artwork_url ? (
                      <Image src={product.artwork_url} alt="Artwork" style={{ maxWidth: '100%', maxHeight: '100px' }} />
                    ) : columnKey === 'select' ? (
                      <Checkbox
                        checked={isProductSelected(product)}
                        onChange={() => toggleProductSelection(product)}
                      />
                    ) : (
                      product[columnKey as keyof Product]?.toString() || 'N/A'
                    )}
                    </div>
                  </Table.Td>
                )}
              </Table.Tr>
            ))}
          </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default ProductGrid;