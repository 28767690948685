import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Paper,
  Text,
  Box,
  Checkbox,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Card,
  Image,
  Badge,
  Accordion,
  Anchor,
  Divider,
  ScrollArea,
  Input
} from '@mantine/core';

import { getGoogleTrendsDaily, filterGeneralTrends, getGoogleTrendsQueryData} from "../../services/automation.service";

// define trend view props
interface TrendViewProps {
  selectedTrends: any[];
  setSelectedTrends: (trends: any[]) => void;
  nextStep: () => void;
  selectedCountry: string;
  setSelectedCountry: Dispatch<SetStateAction<string>>;
  countries: { [key: string]: string };
}

export const TrendView: React.FC<TrendViewProps> = ({
  selectedTrends,
  setSelectedTrends,
  nextStep,
  selectedCountry,
  setSelectedCountry,
  countries
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [niches] = useState<string[]>([]);
    const [trends, setTrends] = useState<any>([]);
    const [filteredQueries, setFilteredQueries] = useState<any[]>([]);

    const [loading, setLoading] = useState<Boolean>(false);
    const [loadingFilter, setLoadingFilter] = useState<Boolean>(false);
    const accessToken = useRef<string>();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    const [customTrend, setCustomTrend] = useState<string>("");
  

    useEffect(() => {
        let isMounted = true;
        
        const getAccessToken = async () => {
            if (!isMounted) {
                return;
              }
            try {
            const token = await getAccessTokenSilently();
            accessToken.current = token;
          } catch (e) {
            console.error(e);
          }
        };
    
        getAccessToken();
        return () => {
          isMounted = false;
        };
      }, [getAccessTokenSilently , selectedTrends, setSelectedTrends]);

      const handleGetTrends = async () => {
        try {
          if (accessToken.current) {
            setIsCountryDisabled(true); // Disable the country selector
            setIsButtonDisabled(true); // Disable the button
            setLoading(true); // Set loading state even if selectedNiches is empty
            const newtrends = await getGoogleTrendsDaily(accessToken.current, selectedCountry);

            if (newtrends ) {
              setTrends([...trends , ...newtrends.data.daily_searches]);
              console.log(newtrends.data.daily_searches);
              setLoading(false); // Reset loading state
              setLoadingFilter(true);
              const filtered = await filterGeneralTrends(accessToken.current,newtrends.data.daily_searches, selectedCountry);
              if (filtered) {
                let search = []
                // in typescript, filtered is an array of strings and we need to use it to filter the trends object, to return a similar object with the filtered queries only
                for (let j = 0; j < newtrends.data.daily_searches.length; j++) {
                  for (let k = 0; k < newtrends.data.daily_searches[j].searches.length; k++) {
                      for (let i = 0; i < filtered.data.length; i++) {
                      if (newtrends.data.daily_searches[j].searches[k].query === filtered.data[i]) {
                        search.push(newtrends.data.daily_searches[j].searches[k]);
                      }
                    }
                  }
                }
              
                console.log(search);
                setFilteredQueries(search);
                setLoadingFilter(false);
              }
            }
          }
        } catch (e) {
          console.error(e);
          setLoading(false); // Reset loading state in case of error
          setIsButtonDisabled(false); // Re-enable the button
          setLoadingFilter(false);
        }
    };
    
    const handleAddCustomTrend = async () => {
      try {
        if (accessToken.current) {
          setIsCountryDisabled(true); // Disable the country selector
          setIsButtonDisabled(true); // Disable the button
          setLoading(true); // Set loading state even if selectedNiches is empty
          const newtrends = await getGoogleTrendsQueryData(accessToken.current, customTrend, selectedCountry);
          if (newtrends ) {
            console.log(newtrends.data);
            setLoading(false); // Reset loading state
            // add it to the trend list 
            newtrends.data.query = customTrend;
            
            newtrends.data.custom = true;
            setTrends([...trends, newtrends.data]);
            // add it to the filtered queries
            setFilteredQueries([...filteredQueries, newtrends.data]);
            setLoadingFilter(false);
          }
        }
      } catch (e) {
        console.error(e);
        setLoading(false); // Reset loading state in case of error
        setIsButtonDisabled(false); // Re-enable the button
        setLoadingFilter(false);
      }
    }

    return (
      <div>
        {/* Header */}
        <Paper shadow="xs"  style={{ backgroundColor: "#1A1B1E", display: "flex",  alignItems: "center", flexDirection: "column", height: "87vh" }}>
        {loading && <LoadingOverlay visible />}
          <Text  color="white" size="xl" >Trends Data Collection</Text>

        {/* Country Selector */}
        <Box>
          <Select
            label="Select Country"
            placeholder="Choose..."
            data={Object.entries(countries).map(([code, name]) => ({ value: code, label: name }))}
            onChange={(value) => {
              console.log(value);
              
              setSelectedCountry(value ?? "DE")
            }}
            value={selectedCountry}
            clearable
            disabled={isCountryDisabled}
          />
          <Button onClick={handleGetTrends} disabled={isButtonDisabled} style={{ marginTop: 10 }}>
            Get Trends
          </Button>
        </Box>

        {/* Trends Display */}
        <ScrollArea style={{ height: '100%', width: "100%", padding: 20 }}>
        <Card style={{ marginBottom: 20, backgroundColor: "#1A1B1E", height: "50%", minHeight: 200  }}>
          { loadingFilter ? <LoadingOverlay visible/> : 
            <div>
            <Text color="white" size="xl" >Filtered Queries for Tshirt:</Text>
            <SimpleGrid cols={3} spacing="md" style={{ padding: 10}}>
              {filteredQueries.map((query: any, i: number) => (
                <Card key={i} shadow="sm" padding="md" style={{ backgroundColor: "#34c3eb", height: 50, display: "flex", flexDirection: "row", justifyContent: "flex-start" }} onClick={() => {
                  // if the trend is already selected, remove it from the list, you should compare the query.query with the selectedTrends query attribute
                  if (selectedTrends.some((trend) => trend.query === query.query)) {
                    setSelectedTrends(selectedTrends.filter((trend) => trend.query !== query.query));
                  } else {
                    console.log(query);
                    setSelectedTrends([...selectedTrends, query]);
                    console.log(selectedTrends);
                  }
                }}>

                  <Checkbox checked={selectedTrends.some((trend) => trend.query === query.query)}
                    style={{ left: 5, alignSelf: "flex-start"}} onChange={() => {console.log(selectedTrends)}} />
                  <div style={ {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%"}}>
                  <Anchor href={query.google_trends_link} target="_blank">
                    <Text color="white">{query.query}</Text>
                  </Anchor>
                  <Badge color="#f3fc74" variant="light">
                    Traffic: {query.traffic}
                  </Badge>
                  </div>
                </Card>
              ))}
              <Card shadow="sm" padding="md" style={{ backgroundColor: "#34c3eb", height: 50, display: "flex", flexDirection: "row", justifyContent: "flex-start" }} onClick={() => {
                if (customTrend !== "") {
                  handleAddCustomTrend();
                }
                else {
                  alert("Please enter a custom trend to search for");
                }
              } }>
                <div style={ {display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%"}}>
                  <Text color="white">ADD MORE +</Text>
                  <Input  placeholder="Enter a custom trend" value={customTrend} onChange={(e) => setCustomTrend(e.currentTarget.value)} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => {
                    if (e.key === "Enter" && customTrend !== "") {
                      handleAddCustomTrend();
                    }
                  
                  }}  />
                  </div>
                </Card>
            </SimpleGrid>
            </div>}
          </Card>
          <Accordion variant="filled">
            {trends.filter((trend: any) => !trend.custom).map((dayData: any, dayIndex: number) => (
              <Accordion.Item value={dayData.date} key={dayIndex}>
                <Accordion.Control>
                  <Text size="lg" >
                    {/* Display the date parsed format is YYYYMMDD*/}
                    {"Trends collected for the " + dayData.date.slice(0, 4) + "-" + dayData.date.slice(4, 6) + "-" + dayData.date.slice(6, 8)}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                <SimpleGrid cols={3} spacing="md" style={{ padding: 10 }}>
                  {dayData.searches.map((search: any, searchIndex: number) => (
                    <Card key={searchIndex} shadow="sm" padding="md">
                      <Text >{search.query}</Text>
                      <Badge color="pink" variant="light">
                        Traffic: {search.traffic}
                      </Badge>

                      {search.related_queries && (
                        <>
                          <Divider my="sm" />
                          <Text>Related Queries:</Text>
                          {search.related_queries.map((query: any, i:number) => (
                            <Anchor key={i} href={query.google_trends_link} target="_blank" size="sm">
                              <Text size="xs" >{query.query}</Text>
                            </Anchor>
                          ))}
                        </>
                      )}

                      {search.articles.map((article:any, articleIndex: number) => (
                        <Card key={articleIndex} shadow="xs" padding="sm" style={{ height: 100, marginTop: 10, display: "flex", flexDirection: "row" }}>
                            <Image src={article.thumbnail} width={100} height={60} alt={article.title} />
                            <div>
                              <Anchor href={article.link} size="sm" target="_blank" >
                              <Text size="xs" >{article.title} </Text>
                              </Anchor>
                              <Text size="xs">{article.snippet}</Text>
                            </div>
                        </Card>
                      ))}
                    </Card>
                  ))}
                </SimpleGrid>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </ScrollArea>

        {/* Footer */}
        <Box  style={{ position: "sticky", bottom: 0, backgroundColor: "#1A1B1E" }}>
          <Button onClick={nextStep} fullWidth size="md">
            Confirm Trend Selection
          </Button>
        </Box>
        </Paper>

      </div>
    );
};